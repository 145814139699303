import { observable } from 'mobx';

const CustomerDetailModel = observable({
    id: '',
    customerType: "",
    businessName: "",
    address: "",
    businessRegistrationNumber: "",
    representative: [],
    taxRefundOfficers: [],
    personInChargeCustomers: [],
    referrer: "",
    selectedTaxRefundOfficerId : ""
});

export default CustomerDetailModel;