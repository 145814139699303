import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './Detail.module.scss';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import vm from './DetailVm';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import { addCommas } from '@common/module/replaceNumber';
import MessageHint from '@commonComponents/MessageHint/MessageHint';
import CopyBtn from '@standby/common-ui/components/atoms/Button/CopyBtn/CopyBtn';
import DatePicker from '@standby/common-ui/components/molecules/DatePicker/DatePicker';

function RefundDetail() {
    let location = useLocation();
    const customerId = queryString.parse(location.search).customerId;
    useEffect(() => {
        vm.load(customerId);
    }, []);
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.pageTitle}>
                <span className={st.title}>세금환급 처리</span>
                    <div className={st.btns}>
                        {vm.taxRefundApplication.taxRefundStatusType !== "진행완료" &&
                            <button className={st.lineBtn} type='button' onClick={() => vm.changeStatus(vm.taxRefundApplication.taxRefundStatusType, customerId)}>{vm.taxRefundApplication.taxRefundStatusType === "진행중" ? "진행취소/보류" : "진행중으로 변경"}</button>
                        }
                        <button className={st.arrowBtn} type='button' onClick={() => vm.openAlimtalkModal(customerId)}>수동 알림톡 발송</button>
                    </div>
            </div>
            <div className={clsx(st.detail, st.top)}>
                <div className={st.top}>
                    <div className={st.companyName}>{vm.taxRefundApplication.businessName}</div>
                    <div className={st.status}>{vm.taxRefundApplication.taxRefundLastStepType}</div>
                </div>
                <div className={st.representatives}>
                    <div className={st.title}>대표</div>
                    <div className={st.content}>{vm.representatives.join(', ')}</div>
                </div>
                <button className={clsx(st.arrowBtn, st.neutral700)} type='button' onClick={() => vm.openUnablekModal(customerId)} style={{top:"26px"}}>서비스제공 불가</button>
                <button className={clsx(st.arrowBtn, st.neutral700)} type='button' onClick={() => vm.openDelaykModal(customerId)} style={{top:"59px"}}>지연메세지 발송</button>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle} style={{top:"13px"}}>가계산 결과발송</div>
                <div className={st.detailContent}>
                    <div className={st.contentRow}>
                        <div className={st.title}>가계산액</div>
                        <div className={st.content}>
                            <InputText name="" value={addCommas(vm.taxRefundApplication.provisionalCalcAmount)} onChange={(e) => vm.changeProvisionalCalcAmount(e)} style={{width:"298px", textAlign:"right"}}/>
                            <span className={st.inputSubText}>원</span>
                        </div>
                    </div>
                    <div className={st.contentRow}>
                        <div className={st.title}>수수료율</div>
                        <div className={st.content}>
                            <InputText name="" value={vm.taxRefundApplication.provisionalCalcCommissionRate} onChange={(e) => vm.changeProvisionalCalcCommissionRate(e)} style={{width:"298px", textAlign:"right"}}/>
                            <span className={st.inputSubText}>%</span>
                        </div>
                    </div>
                    <div className={clsx(st.contentRow, st.checkboxRow)}>
                        <div className={st.title}>발송방식</div>
                        <div className={st.content}>
                            <div className={st.checkListWrap}>
                                {vm.personInCharges.map((personInCharge, index) => (
                                    <ul className={st.checkboxList} key={index}>
                                        {personInCharge.phoneNumber && 
                                            <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendAlimtalk', 'provisionalCalcSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendAlimtalk', 'provisionalCalcSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}알림톡 {personInCharge.phoneNumber}</CheckBox></li>
                                        }
                                        {personInCharge.email && 
                                            <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendEmail', 'provisionalCalcSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendEmail', 'provisionalCalcSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}메일 {personInCharge.email}</CheckBox></li>
                                        }
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={st.btnWrap}>
                        <button type='button' disabled={!vm.provisionalCalcActive} className={st.mainBtn} onClick={() => vm.sendProvisionalCalc(customerId)}><span>가계산 결과발송</span></button>
                    </div>
                    <button type="button" className={st.fixedSave} disabled={!vm.provisionalCalcActive} onClick={() => vm.updateProvisionalCalc(customerId)}>수정사항 저장</button>
                    <MessageHint text="알림톡, 이메일 미리보기" style={{marginTop:'4px'}}>
                        <img src='/images/provisionalCalcSendPreview.svg'/>
                    </MessageHint>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle}>수임계약서</div>
                <div className={st.detailContent}>
                    <div className={st.textRow}>전자서명일자 : {vm.taxRefundApplication.acceptAt}</div>
                    <div className={st.btnWrap} style={{marginTop:"8px"}}>
                        <button type='button' disabled={!vm.taxRefundApplication.acceptAt} className={clsx(st.mainBtn, st.download)} onClick={() => vm.downloadContractFile(customerId)}><span>다운로드</span></button>
                    </div>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle} style={{top:"13px"}}>실계산 결과발송</div>
                <div className={st.detailContent}>
                    <div className={st.contentRow}>
                        <div className={st.title}>실계산액</div>
                        <div className={st.content}>
                            <InputText name="" value={addCommas(vm.taxRefundApplication.actualAmount)} onChange={(e) => vm.changeActualAmount(e)} style={{width:"298px", textAlign:"right"}}/>
                            <span className={st.inputSubText}>원</span>
                            <button type='button' className={st.loadBtn} onClick={() => vm.pasteProvisionalCalcAmount()}>가계산결과 불러오기</button>
                        </div>
                    </div>
                    <div className={clsx(st.contentRow, st.checkboxRow)}>
                        <div className={st.title}>발송방식</div>
                        <div className={st.content}>
                            <div className={st.checkListWrap}>
                                {vm.personInCharges.map((personInCharge, index) => (
                                    <ul className={st.checkboxList} key={index}>
                                        {personInCharge.phoneNumber && 
                                            <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendAlimtalk', 'actualAmountSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendAlimtalk', 'actualAmountSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}알림톡 {personInCharge.phoneNumber}</CheckBox></li>
                                        }
                                        {personInCharge.email && 
                                            <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendEmail', 'actualAmountSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendEmail', 'actualAmountSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}메일 {personInCharge.email}</CheckBox></li>
                                        }
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={st.btnWrap}>
                        <button type='button' disabled={!vm.actualAmountActive} className={st.mainBtn} onClick={() => vm.sendActualAmount(customerId)}><span>실계산 결과발송</span></button>
                    </div>
                    <MessageHint text="알림톡, 이메일 미리보기" style={{marginTop:'4px'}}>
                        <img src='/images/actualAmountPreview.svg'/>
                    </MessageHint>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle}>환급 계좌정보</div>
                <div className={st.detailContent}>
                    <div className={st.textRow}><span className={st.rowTitle}>은행</span> <CopyBtn className={st.copyBtn} copyText={vm.taxRefundApplication.refundBank}>{vm.taxRefundApplication.refundBank}</CopyBtn></div>
                    <div className={st.textRow}><span className={st.rowTitle}>계좌번호</span> <CopyBtn className={st.copyBtn} copyText={vm.taxRefundApplication.refundAccount}>{vm.taxRefundApplication.refundAccount}</CopyBtn></div>
                    <div className={st.textRow}><span className={st.rowTitle}>예금주</span> <CopyBtn className={st.copyBtn} copyText={vm.taxRefundApplication.refundAccountHolder}>{vm.taxRefundApplication.refundAccountHolder}</CopyBtn></div>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle}>세무서 접수</div>
                <div className={st.detailContent}>
                    <div className={clsx(st.contentRow, st.checkboxRow)}>
                        <div className={st.title}>발송방식</div>
                        <div className={st.content}>
                            <div className={st.checkListWrap}>
                                {vm.personInCharges.map((personInCharge, index) => (
                                    <ul className={st.checkboxList} key={index}>
                                        {personInCharge.phoneNumber && 
                                            <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendAlimtalk', 'personInChargeCustomerList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendAlimtalk', 'personInChargeCustomerList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}알림톡 {personInCharge.phoneNumber}</CheckBox></li>
                                        }
                                    </ul>
                                ))}
                            </div>
                        </div>
                    </div>
                    <div className={st.btnWrap}>
                        <button type='button' disabled={!vm.taxOfficeReceptionActive} className={st.mainBtn} onClick={() => vm.sendTaxOfficeReception(customerId)}><span>세무서 접수완료 발송</span></button>
                    </div>
                    <MessageHint text="알림톡 미리보기" style={{marginTop:'4px'}}>
                        <img src='/images/taxOfficeReceptionPreview.svg'/>
                    </MessageHint>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle} style={{top:"13px"}}>청구서 발송</div>
                <div className={st.detailContent}>
                    <div className={st.contentWrap}>
                        <div className={st.contentRow}>
                            <div className={st.title}>환급액</div>
                            <div className={st.content}>
                                <InputText name="" value={addCommas(vm.taxRefundApplication.refundAmount)} onChange={(e) => vm.changeRefundAmount(e)} style={{width:"298px", textAlign:"right"}}/>
                                <span className={st.inputSubText}>원</span>
                                <button type='button' className={st.loadBtn} onClick={() => vm.pasteActualAmount()}>실계산결과 불러오기</button>
                            </div>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.title}>수수료율</div>
                            <div className={st.content}>
                                <InputText name="" value={vm.taxRefundApplication.refundCommissionRate} onChange={(e) => vm.changeRefundCommissionRate(e)} style={{width:"298px", textAlign:"right"}}/>
                                <span className={st.inputSubText}>%</span>
                            </div>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.title}>자동계산보수</div>
                            <div className={st.content}>
                                <span className={st.countText}>{vm.calculatePercentageAndRound(vm.taxRefundApplication.refundAmount, vm.taxRefundApplication.refundCommissionRate)}</span>
                                <span className={st.inputSubText}>원</span>
                            </div>
                        </div>
                        <div className={st.infoTextRow}>
                            <span className={st.infoText}>천 원 미만은 버림처리</span>
                        </div>
                    </div>
                    <div className={st.contentWrap}>
                        <div className={clsx(st.contentRow, st.radioRow)}>
                            <div className={st.title}>할인제공</div>
                            <div className={st.content}>
                                <ul className={st.radioList}>
                                    <li><Radio name="discountType" value="AMOUNT" checked={vm.taxRefundApplication.discountType === "AMOUNT"} onChange={(e) => vm.changeDiscountType(e)}>정액할인</Radio></li>
                                    <li><Radio name="discountType" value="PERCENTAGE" checked={vm.taxRefundApplication.discountType === "PERCENTAGE"} onChange={(e) => vm.changeDiscountType(e)}>%로 할인</Radio></li>
                                </ul>
                            </div>
                        </div>
                        {vm.taxRefundApplication.discountType === "AMOUNT" && 
                            <div className={st.contentRow} style={{marginTop:"8px"}}>
                                <div className={st.title}></div>
                                <div className={st.content}>
                                    <InputText name="" value={addCommas(vm.taxRefundApplication.discountAmount)} onChange={(e) => vm.changeDiscountAmount(e)} style={{width:"298px", textAlign:"right"}}/>
                                    <span className={st.inputSubText}>원</span>
                                </div>
                            </div>
                        }
                        {vm.taxRefundApplication.discountType === "PERCENTAGE" && 
                            <div className={st.contentRow} style={{marginTop:"8px"}}>
                                <div className={st.title}></div>
                                <div className={st.content}>
                                    <InputText name="" value={addCommas(vm.taxRefundApplication.discountRate)} onChange={(e) => vm.changeDiscountRate(e)} style={{width:"298px", textAlign:"right"}}/>
                                    <span className={st.inputSubText}>%</span>
                                </div>
                            </div>
                        }
                        <div className={st.infoTextRow} style={{justifyContent:"start", marginLeft:"93px", marginTop:"8px"}}>
                            <span className={st.infoText}>할인이 없으면 선택하지 않아도 됩니다.</span>
                        </div>
                        <div className={st.infoTextRow} style={{justifyContent:"start", marginLeft:"93px"}}>
                            <span className={st.infoText}>%로 할인 시, 천 원 미만은 버림처리</span>
                        </div>
                        <div className={st.contentRow}>
                            <div className={st.title}>최종계산보수</div>
                            <div className={st.content}>
                                <span className={st.countText}>{vm.discountSum}</span>
                                <span className={st.inputSubText}>원</span>
                            </div>
                        </div>
                        <div className={st.infoTextRow}>
                            <span className={st.infoText}>천 원 미만은 버림처리</span>
                        </div>
                    </div>
                    <div className={st.contentWrap}>
                        <div className={clsx(st.contentRow, st.checkboxRow)}>
                            <div className={st.title}>발송방식</div>
                            <div className={st.content}>
                                <div className={st.checkListWrap}>
                                    {vm.personInCharges.map((personInCharge, index) => (
                                        <ul className={st.checkboxList} key={index}>
                                            {personInCharge.phoneNumber && 
                                                <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendAlimtalk', 'billSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendAlimtalk', 'billSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}알림톡 {personInCharge.phoneNumber}</CheckBox></li>
                                            }
                                            {personInCharge.email && 
                                                <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendEmail', 'billSendList')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendEmail', 'billSendList')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}메일 {personInCharge.email}</CheckBox></li>
                                            }
                                        </ul>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={st.btnWrap}>
                        <button type='button' disabled={!vm.billActive} className={st.mainBtn} onClick={() => vm.sendBill(customerId)}><span>청구서 발송</span></button>
                    </div>
                    <MessageHint text="알림톡 미리보기" style={{marginTop:'4px'}}>
                        <img src='/images/billPreview.svg'/>
                    </MessageHint>
                </div>
            </div>
            <div className={st.detail}>
                <div className={st.detailTitle} style={{top:"13px"}}>수임료 입금확인</div>
                <div className={st.detailContent}>
                    <div className={st.contentRow}>
                        <div className={st.title}>수임액</div>
                        <div className={st.content}>
                            <InputText name="" value={addCommas(vm.taxRefundApplication.retainedAmount)} onChange={(e) => vm.changeRetainedAmount(e)} style={{width:"298px", textAlign:"right"}}/>
                            <span className={st.inputSubText}>원</span>
                        </div>
                    </div>
                    <div className={st.contentRow}>
                        <div className={st.title}>입금일자</div>
                        <div className={st.content}>
                            <DatePicker name="" selected={vm.taxRefundApplication.depositAt} onChange={(e) => vm.changeDepositAt(e)} className={st.date}/>
                        </div>
                    </div>
                    <div className={st.btnWrap}>
                        <button type='button' disabled={!vm.completeActive} className={clsx(st.mainBtn, st.noneIcon)} onClick={() => vm.saveComplete(customerId)}><span>완료처리 하기</span></button>
                    </div>
                </div>
            </div>
        </div>
    ));
}
export default RefundDetail;