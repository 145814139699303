import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import RefundListModel from '@model/RefundListModel';

class RefundListService {
    async load() {
        const res = await get(`/admin/refund/grid/column`);
        RefundListModel.setColumns(res.data.columns);
    }
    async gridData(params) {
        const res = await post('/admin/refund/grid', params);
        RefundListModel.setTabs(res.data.tabs);
        return res.data;
    }
    async onChangeData(params) {
        await put('/admin/refund/grid/column', params);
    }
}
export default new RefundListService();