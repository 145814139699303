import { post, get, _delete, put, downloadFileGet, patch } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import RefundDetailModel from '@model/RefundDetailModel';
import { _alert, _confirm } from "@model/dialogModel";

class RefundDetailService {
    async load(customerId) {
        const res = await get(`/admin/tax-refund/${customerId}`);
        runInAction(() => {
            RefundDetailModel.taxRefundApplication = {
                provisionalCalcAmount : res?.data?.taxRefundApplication?.provisionalCalcAmount ?? '',
                provisionalCalcCommissionRate : res?.data?.taxRefundApplication?.provisionalCalcCommissionRate ?? '',
                taxRefundStatusType : res?.data?.taxRefundApplication?.taxRefundStatusType || '',
                taxRefundLastStepType : res?.data?.taxRefundApplication?.taxRefundLastStepType || '',
                businessName : res?.data?.taxRefundApplication?.businessName || '',
                canProvideService : res?.data?.taxRefundApplication?.canProvideService ?? null,
                acceptAt : res?.data?.taxRefundApplication?.acceptAt || '',
                actualAmount : res?.data?.taxRefundApplication?.actualAmount ?? '',
                refundAmount : res?.data?.taxRefundApplication?.refundAmount ?? '',
                refundCommissionRate : res?.data?.taxRefundApplication?.refundCommissionRate ?? '',
                discountAmount : res?.data?.taxRefundApplication?.discountAmount ?? '',
                discountRate : res?.data?.taxRefundApplication?.discountRate ?? '',
                discountType : res?.data?.taxRefundApplication?.discountType || null,
                retainedAmount : res?.data?.taxRefundApplication?.retainedAmount ?? '',
                depositAt : res?.data?.taxRefundApplication?.depositAt || '',
                refundBank : res?.data?.taxRefundApplication?.refundBank || '',
                refundAccount : res?.data?.taxRefundApplication?.refundAccount || '',
                refundAccountHolder : res?.data?.taxRefundApplication?.refundAccountHolder || '',
            };
            RefundDetailModel.representatives = res?.data?.representatives || [];
            RefundDetailModel.personInCharges = res?.data?.personInCharges.map((personInCharge) => {
                return {
                    id : personInCharge?.id ?? "",
                    name : personInCharge?.name || "",
                    phoneNumber : personInCharge?.phoneNumber || "",
                    email : personInCharge?.email || "" 
                }
            });
        })
    }
    async updateProvisionalCalc(param, customerId) {
        const res = await put(`/admin/tax-refund/${customerId}/provisional-calculate`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async sendProvisionalCalc(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/provisional-calculate/notification`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
    async downloadContractFile(customerId) {
        await downloadFileGet(`/admin/tax-refund/${customerId}/contractFile`);
    }
    async sendActualAmount(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/actual-calculate/notification`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
    async sendTaxOfficeReception(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/taxOfficeReception`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
    async sendBill(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/bill`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
    async saveComplete(param, customerId) {
        const res = await patch(`/admin/tax-refund/${customerId}/complete`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async changeStatus(customerId, changeStatusType) {
        const res = await patch(`/admin/tax-refund/${customerId}/status`);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert(`${changeStatusType}상태로 변경되었습니다.`);
        window.location.reload();
    }
    async unableAlimtalkSend(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/service/unable`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
    async delayAlimtalkSend(param, customerId) {
        const res = await post(`/admin/tax-refund/${customerId}/delay`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('발송요청 되었습니다.');
        window.location.reload();
    }
}
export default new RefundDetailService();