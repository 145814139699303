import { get } from '@common/module/httpRequest';
import userModel from '@model/userModel';

class presetService {
    async load() {
        const res = await get('/preset');
        //유저세팅
        userModel.setUser(res.data.user);
    }
}
export default new presetService();