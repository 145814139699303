import { runInAction, observable, toJS } from "mobx";
import CustomerDetailModel from '@model/CustomerDetailModel';
import CustomerDetailService from '@service/CustomerDetailService';
import { _alert, _confirm } from "@model/dialogModel";
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';

class CustomerDetailVm {
    load(customerId) {
        CustomerDetailService.load(customerId);
    }
    get customerType() {
        return CustomerDetailModel.customerType;
    }
    get businessName() {
        return CustomerDetailModel.businessName;
    }
    get businessRegistrationNumber() {
        return CustomerDetailModel.businessRegistrationNumber;
    }
    get representative() {
        return CustomerDetailModel.representative;
    }
    get taxRefundOfficers() {
        return CustomerDetailModel.taxRefundOfficers;
    }
    get selectedTaxRefundOfficerId() {
        return CustomerDetailModel.selectedTaxRefundOfficerId;
    }
    get personInChargeCustomers() {
        return CustomerDetailModel.personInChargeCustomers;
    }
    get address() {
        return CustomerDetailModel.address;
    }
    get referrer() {
        return CustomerDetailModel.referrer;
    }
    changeCustomerType(e) {
        CustomerDetailModel.customerType = e.target.value;
    }
    changeBusinessName(e) {
        CustomerDetailModel.businessName = e.target.value;
    }
    changeAddress(e) {
        CustomerDetailModel.address = e.target.value;
    }
    changeBusinessRegistrationNumber(e) {
        CustomerDetailModel.businessRegistrationNumber = e.target.value.replace(/(\d{3})(\d{2})(\d{5})/, '$1-$2-$3');;
    }
    changeRepresentative(e,index) {
        CustomerDetailModel.representative[index] = e.target.value;
    }
    addRepresentative(index) {
        CustomerDetailModel.representative.splice(index+1, 0, "")
    }
    removeRepresentative(index) {
        CustomerDetailModel.representative.splice(index, 1)
    }
    changeSelectedTaxRefundOfficerId(e) {
        CustomerDetailModel.selectedTaxRefundOfficerId = e.target.value;
    }
    changePersonInChargeCustomerName(e, index) {
        CustomerDetailModel.personInChargeCustomers[index].name = e.target.value;
    }
    changePersonInChargeCustomerPhoneNumber(e, index) {
        CustomerDetailModel.personInChargeCustomers[index].phoneNumber = e.target.value.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3');;
    }
    changePersonInChargeCustomerEmail(e, index) {
        CustomerDetailModel.personInChargeCustomers[index].email = e.target.value;
    }
    changeReferrer(e, index) {
        CustomerDetailModel.referrer = e.target.value;
    }
    addPersonInChargeCustomer(index) {
        CustomerDetailModel.personInChargeCustomers.splice(index+1, 0, {
            "name": "",
            "phoneNumber": "",
            "email": ""
        })
    }
    removePersonInChargeCustomer(index) {
        CustomerDetailModel.personInChargeCustomers.splice(index, 1)
    }
    async deleteCustomer(customerId) {
        if(await _confirm('정말 삭제하나요?', '고객삭제를 하는 경우, 데이터 복구가 불가합니다.')) {
            CustomerDetailService.deleteCustomer(customerId);
        }
    }
    openAlimtalkModal(customerId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal customerId={customerId}/>;
        })
    }
    get saveBtnActive() {
        let active = true;
        if(!this.customerType) { //고객종류
            active = false;
        }
        if(!this.businessName) { //사업체명
            active = false;
        }
        if(!this.address) { //주소
            active = false;
        }
        if(!this.businessRegistrationNumber) { //사업자등록번호
            active = false;
        }
        this.representative.forEach(item => {
            if(!item) {
                active = false;
            }
        });
        this.personInChargeCustomers.forEach(item => {
            if((!item.phoneNumber || !item.email)) {
                active = false;
            }
        });
        return active;
    }
    get saveParam() {
        let param = {
            customerType : this.customerType,
            businessName : this.businessName,
            address : this.address,
            businessRegistrationNumber : this.businessRegistrationNumber,
            representative : this.representative,
            selectedTaxRefundOfficerId : this.selectedTaxRefundOfficerId,
            personInChargeCustomers : this.personInChargeCustomers,
            referrer : this.referrer
        }
        return param;
    }
    async updateCustomer(customerId) {
        const param = this.saveParam;
        if(await _confirm('수정사항을 저장하시겠습니까?')) {
            await CustomerDetailService.updateCustomer(param, customerId);
        }
    }
    async saveCustomer() {
        const param = this.saveParam;
        if(await _confirm('고객을 등록하시겠습니까??')) {
            await CustomerDetailService.saveCustomer(param);
        }
    }
}
export default new CustomerDetailVm();