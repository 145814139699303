import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import React, { useEffect, useRef, useState, useMemo } from 'react';
import vm from './CustomerListVm';
import PageNav from '@commonComponents/PageNav/PageNav';
import st from './CustomerList.module.scss';
import clsx from 'clsx';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import BusinessName from '@pages/GridCommon/BusinessName/BusinessName';
import gridLocale from '@common/module/gridLocale.ko.js';
import queryString from 'query-string';
import RowNumberRenderer from '@pages/GridCommon/RowNumberRenderer/RowNumberRenderer';
import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-048028 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( standbylab )_is_granted_a_( Multiple Applications )_Developer_License_for_( 1 ))_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_need_to_be_licensed_in_addition_to_the_ones_working_with_AG_Grid_Enterprise___This_key_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 19 September 2024 )____[v2]_MTcyNjcwMDQwMDAwMA==ddf0238f8ac3458090061890759fab84");

function CustomerList() {
    const gridRef = useRef(); 
    let location = useLocation();

    useEffect(() => {
        vm.load();
    }, [])

    return useObserver(() => (
        <>
            <PageNav nav={["고객관리"]}/>
            <div className={st.gridContent}>
                <button className={st.openCustomerDetail} type="button" onClick={() => vm.openWindow()}>고객등록</button>
                {vm.columns.length !== 0 &&
                    <>
                        <div style={{paddingTop:"20px"}}>
                            <Grid gridRef={gridRef}/>
                        </div>
                        <div className={st.gridPageSize}>
                            <span className={st.title}>Page Size</span> 
                            <select onChange={(e) => vm.onGridPageSizeChanged(e, gridRef)} defaultValue={"30"} className={st.selectedSmall}>
                                <option value="30">30</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                        </div>
                    </>
                }
            </div>
        </>
    ));
}
const Grid = React.memo(function (props) {
    const defaultColDef = useMemo(() => ({
        sortable: true,
        floatingFilter: true,
        resizable: true
    }));
    const components = useMemo(() => ({
        "BusinessNameRenderer" : BusinessName,
        "RowNumberRenderer" : RowNumberRenderer
    }), [])
    return useObserver(() => (
        <div className={clsx(st.grid, "ag-theme-alpine")}>
            <AgGridReact pagination={true} paginationPageSize={30} cacheBlockSize={30} ref={props.gridRef} localeText={gridLocale} components={components} columnDefs={vm.columns} defaultColDef={defaultColDef} rowModelType={'serverSide'} serverSideDatasource={vm.gridData()} suppressBrowserResizeObserver={true} onCellEditingStopped={vm.onChangeData}/>
        </div>
    ));
})
export default CustomerList;