import RefundDetailModel from '@model/RefundDetailModel';
import RefundDetailService from '@service/RefundDetailService';
import { removeCommas, onlyNumber } from '@common/module/replaceNumber';
import { observable, runInAction, toJS } from 'mobx';
import { addCommas } from '@common/module/replaceNumber';
import portalModel from '@model/portalModel';
import AlimtalkSendModal from '@pages/AlimtalkSendModal/AlimtalkSendModal';
import { _alert, _confirm } from "@model/dialogModel";
import UnableAlimtalkSendModal from './UnableAlimtalkSendModal/UnableAlimtalkSendModal';
import DelayAlimtalkSendModal from './DelayAlimtalkSendModal/DelayAlimtalkSendModal';

class DetailVm {
    constructor() {
        this.state = observable({
            provisionalCalcSendList: [],//가계산 결과발송 체크리스트
            actualAmountSendList : [], //실계산 결과발송 체크리스트
            personInChargeCustomerList : [], //세무서 접수발송 체크리스트
            billSendList : [] //청구서 발송 리스트
        })
    }
    load(customerId) {
        RefundDetailService.load(customerId);
    }
    get taxRefundApplication() {
        return RefundDetailModel.taxRefundApplication;
    }
    get personInCharges() {
        return RefundDetailModel.personInCharges;
    }
    get representatives() {
        return RefundDetailModel.representatives;
    }
    openAlimtalkModal(customerId) {
        runInAction(() => {
            portalModel.title = "수동 알림톡 발송";
            portalModel.content = <AlimtalkSendModal customerId={customerId}/>;
        })
    }
    changeProvisionalCalcAmount(e) {
        this.taxRefundApplication.provisionalCalcAmount = onlyNumber(removeCommas(e.target.value));
    }
    changeProvisionalCalcCommissionRate(e) {
        this.taxRefundApplication.provisionalCalcCommissionRate = onlyNumber(e.target.value);
    }
    sendList(e, id, key, checkedArray) {
        const checked = e.target.checked;
        let hasItem = false;
        this.state[checkedArray].forEach(sendItem => {
            if(sendItem.id === id) {
                hasItem = true;
                sendItem[key] = checked;
            }
        });
        if(hasItem === false) {
            this.state[checkedArray].push({
                id : id,
                [key] : checked
            })
        }
    }
    sendChecked(id, key, checkedArray) {
        let checked = false;
        if(checked === false) {
            this.state[checkedArray].forEach(sendItem => {
                if(sendItem.id === id) {
                    if(sendItem[key]) {
                        checked = true;
                    } 
                }
            });
        }
        return checked;
    }
    get provisionalCalcActive() {
        let active = true;
        if(this.taxRefundApplication.provisionalCalcAmount === '') { //가계산액
            active = false;
        }
        if(this.taxRefundApplication.provisionalCalcCommissionRate === '') { //가계산액 수수료율
            active = false;
        }
        let provisionalCalcSendList = false;
        this.state.provisionalCalcSendList.forEach(provisionalCalcSend => {
            if(provisionalCalcSend?.sendAlimtalk === true || provisionalCalcSend?.sendEmail === true) {
                provisionalCalcSendList = true;
            }
        });
        active = provisionalCalcSendList;
        return active;
    }
    get provisionalCalcParam() {
        return {
            provisionalCalcAmount : this.taxRefundApplication.provisionalCalcAmount,
            provisionalCalcCommissionRate : this.taxRefundApplication.provisionalCalcCommissionRate,
            recipients : this.state.provisionalCalcSendList
        }
    }
    sendProvisionalCalc(customerId) {
        RefundDetailService.sendProvisionalCalc(this.provisionalCalcParam, customerId)
    }
    updateProvisionalCalc(customerId) {
        RefundDetailService.updateProvisionalCalc(this.provisionalCalcParam, customerId)
    }
    downloadContractFile(customerId) {
        RefundDetailService.downloadContractFile(customerId);
    }
    changeActualAmount(e) {
        this.taxRefundApplication.actualAmount = onlyNumber(removeCommas(e.target.value));
    }
    pasteProvisionalCalcAmount() {
        this.taxRefundApplication.actualAmount = this.taxRefundApplication.provisionalCalcAmount
    }
    get actualAmountActive() {
        let active = true;
        if(this.taxRefundApplication.actualAmount === '') { //실산액
            active = false;
        }
        let actualAmountSendList = false;
        this.state.actualAmountSendList.forEach(actualAmountSend => {
            if(actualAmountSend?.sendAlimtalk === true || actualAmountSend?.sendEmail === true) {
                actualAmountSendList = true;
            }
        });
        active = actualAmountSendList;
        return active;
    }
    sendActualAmount(customerId) {
        const param = {
            actualAmount : this.taxRefundApplication.actualAmount,
            recipients : this.state.actualAmountSendList
        }
        RefundDetailService.sendActualAmount(param, customerId)
    }
    get taxOfficeReceptionActive() {
        let active = true;
        let personInChargeCustomerList = false;
        this.state.personInChargeCustomerList.forEach(personInChargeCustomer => {
            if(personInChargeCustomer?.sendAlimtalk === true) {
                personInChargeCustomerList = true;
            }
        });
        active = personInChargeCustomerList;
        return active;
    }
    sendTaxOfficeReception(customerId) {
        let personInChargeCustomerIds = [];
        this.state.personInChargeCustomerList.forEach((personInChargeCustomer) => {
            if(personInChargeCustomer?.sendAlimtalk === true) {
                personInChargeCustomerIds.push(personInChargeCustomer.id);
            }
        })
        const param = {
            personInChargeCustomerIds
        }
        RefundDetailService.sendTaxOfficeReception(param, customerId)
    }
    changeRefundAmount(e) {
        this.taxRefundApplication.refundAmount = onlyNumber(removeCommas(e.target.value));
    }
    pasteActualAmount() {
        this.taxRefundApplication.refundAmount = this.taxRefundApplication.actualAmount
    }
    changeRefundCommissionRate(e) {
        this.taxRefundApplication.refundCommissionRate = onlyNumber(e.target.value);
    }
    calculatePercentageAndRound(n, a) {
        let rate = a / 100;
        let amount = n * rate;
        let truncatedAmount = Math.floor(amount / 1000) * 1000;
        return addCommas(truncatedAmount);
    }
    changeDiscountType(e) {
        this.taxRefundApplication.discountType = e.target.value;
    }
    changeDiscountAmount(e) {
        this.taxRefundApplication.discountAmount = onlyNumber(removeCommas(e.target.value));
    }
    changeDiscountRate(e) {
        this.taxRefundApplication.discountRate = onlyNumber(removeCommas(e.target.value));
    }
    get discountSum() {
        const beforePrice = onlyNumber(removeCommas(this.calculatePercentageAndRound(this.taxRefundApplication.refundAmount, this.taxRefundApplication.refundCommissionRate)));
        if(this.taxRefundApplication.discountType === "AMOUNT") {
            return addCommas(beforePrice - Math.floor(this.taxRefundApplication.discountAmount / 1000) * 1000);
        }else if(this.taxRefundApplication.discountType === "PERCENTAGE") {
            return addCommas(beforePrice - removeCommas(this.calculatePercentageAndRound(beforePrice, this.taxRefundApplication.discountRate)));
        }else{
            return '-'
        }
    }
    get billActive() {
        let active = true;
        if(this.taxRefundApplication.refundAmount === '') { //환급액
            active = false;
        }
        if(this.taxRefundApplication.refundCommissionRate === '') { //수수료율
            active = false;
        }
        let billSendList = false;
        this.state.billSendList.forEach(billSend => {
            if(billSend?.sendAlimtalk === true || billSend?.sendEmail === true) {
                billSendList = true;
            }
        });
        active = billSendList;
        return active;
    }
    sendBill(customerId) {
        const param = {
            refundAmount: this.taxRefundApplication.refundAmount,
            refundCommissionRate: this.taxRefundApplication.refundCommissionRate,
            discountType: this.taxRefundApplication.discountType,
            discountAmount: this.taxRefundApplication.discountAmount,
            discountRate: this.taxRefundApplication.discountRate,
            recipients : this.state.billSendList
        }
        RefundDetailService.sendBill(param, customerId)
    }
    changeRetainedAmount(e) {
        this.taxRefundApplication.retainedAmount = onlyNumber(removeCommas(e.target.value));
    }
    changeDepositAt(e) {
        let date = null;
        if(e) {
            const year = e.getFullYear();
            const month = ('0' + (e.getMonth() + 1)).slice(-2);
            const day = ('0' + e.getDate()).slice(-2);
            date = year+"-"+month+"-"+day;
        }
        this.taxRefundApplication.depositAt = date;
    }
    get completeActive() {
        let active = true;
        if(this.taxRefundApplication.retainedAmount === '') { //수임액
            active = false;
        }
        if(this.taxRefundApplication.depositAt === '') { //입금일자
            active = false;
        }
        return active;
    }
    saveComplete(customerId) {
        const param = {
            retainedAmount: this.taxRefundApplication.retainedAmount,
            depositAt: this.taxRefundApplication.depositAt
        }
        RefundDetailService.saveComplete(param, customerId)
    }
    async changeStatus(statusType, customerId) {
        let text = '';
        if(statusType === "진행중") {
            text = '진행취소/보류 상태로 변경하나요?'
        }
        if(statusType === "진행취소") {
            text = '진행중으로 변경하나요?'
        }
        if(await _confirm(text)) {
            let changeStatusType = '';
            if(statusType === "진행중") {
                changeStatusType = '진행취소/보류';
            }
            if(statusType === "진행취소") {
                changeStatusType = '진행중';
            }
            RefundDetailService.changeStatus(customerId, changeStatusType);
        }
    }
    openUnablekModal(customerId) {
        runInAction(() => {
            portalModel.title = "서비스제공 불가";
            portalModel.content = <UnableAlimtalkSendModal customerId={customerId}/>;
        })
    }
    openDelaykModal(customerId) {
        runInAction(() => {
            portalModel.title = "지연 메세지 발송";
            portalModel.content = <DelayAlimtalkSendModal customerId={customerId}/>;
        })
    }
}
export default new DetailVm();