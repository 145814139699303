import { post, get } from '@common/module/httpRequest';
import UserSettingModel from '@model/UserSettingModel';
import userModel from '@model/userModel';

class UserSettingService {
    async load() {
        const res = await get('/my-account');
        UserSettingModel.setUserSetData(res.data);
    }
    async changePassword(param) {
        await post('/my-account/change-password', {
            password : param.password,
            newPassword : param.newPassword
        });
    }
    async changeName(param) {
        await post('/my-account/change-name', {
            name : param.name
        });
        userModel.user.name = param.name;
    }
}
export default new UserSettingService();