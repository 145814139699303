import { post, get, _delete, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import CustomerDetailModel from '@model/CustomerDetailModel';
import { _alert, _confirm } from "@model/dialogModel";

class CustomerDetailService {
    async load(customerId) {
        const res = await get(customerId ? `/admin/customer/${customerId}` : '/admin/customer');
        
        runInAction(() => {
            CustomerDetailModel.id = res?.data?.id || "";
            CustomerDetailModel.customerType = res?.data?.customerType || "";
            CustomerDetailModel.businessName = res?.data?.businessName || "";
            CustomerDetailModel.address = res?.data?.address || "";
            CustomerDetailModel.businessRegistrationNumber = res?.data?.businessRegistrationNumber || "";
            if(res?.data?.representative && res?.data?.representative.length !== 0) {
                CustomerDetailModel.representative = res?.data?.representative;
            }else{
                CustomerDetailModel.representative = [""];
            }
            CustomerDetailModel.taxRefundOfficers = res?.data?.taxRefundOfficers || [];
            if(res?.data?.personInChargeCustomers && res?.data?.personInChargeCustomers.length !== 0) {
                CustomerDetailModel.personInChargeCustomers = res?.data?.personInChargeCustomers;
            }else{
                CustomerDetailModel.personInChargeCustomers = [{
                    "name": "",
                    "phoneNumber": "",
                    "email": ""
                }];
            }
            CustomerDetailModel.referrer = res?.data?.referrer || "";
            CustomerDetailModel.selectedTaxRefundOfficerId = res?.data?.selectedTaxRefundOfficerId || "";
        })
    }
    async deleteCustomer(customerId) {
        await _delete(`/admin/customer/${customerId}`);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('삭제되었습니다.');
        window.close();
    }
    async updateCustomer(param, customerId) {
        const res = await put(`/admin/customer/${customerId}`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('저장되었습니다.');
        window.location.reload();
    }
    async saveCustomer(param) {
        const res = await post(`/admin/customer`, param);
        if(window?.opener?.location) {
            window.opener.location.reload();
        }
        await _alert('등록되었습니다.');
        window.close();
    }
}
export default new CustomerDetailService();