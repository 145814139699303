import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import HeaderVm from './HeaderVm';
import st from './Header.module.scss';
import { Link, Route, withRouter, useLocation } from "react-router-dom";
import clsx from 'clsx';
import DialogBtn from '@standby/common-ui/components/atoms/Button/DialogBtn/DialogBtn';

function Header() {
    let location = useLocation();
    return useObserver(() => (
        <header id={st.header}>
            <div className={st.content}>
                <Link className={st.logo} to="/">
                    <div className={st.logoIcon}>
                        <img src='/images/companyLogo.svg'/>
                    </div>
                    <span>진평회계법인</span>
                </Link>
                <div className={st.menuCover}>
                    <ul className={st.mainMenu}>
                        <li className={location.pathname.split("/")[1] === "customer"  ? st.active : null}><span><Link to="/customer/list">고객관리</Link></span></li>
                        <li className={location.pathname.split("/")[1] === "refund" || location.pathname === "/" ? st.active : null}><span><Link to="/refund/list">환급신청</Link></span></li>
                    </ul>
                </div>
                <div className={st.menuBackgorund}></div>
                <div className={st.side}>
                    <button type="button" className={clsx(st.buttonIcon, st.mypage)} onClick={() => HeaderVm.openUserSetting()}>{HeaderVm.userName}님</button>
                    <button type="button" className={st.logout} onClick={HeaderVm.logout}/>
                </div>
            </div>
        </header>
    ));
}
export default Header;