import { runInAction, observable, toJS } from "mobx";
import userModel from '@model/userModel';
import LoginService from '@service/LoginService';
import { _alert, _confirm } from "@model/dialogModel";
import portalModel from '@model/portalModel';
import UserSetting from '@pages/UserSetting/UserSetting';

class HeaderVm {
    constructor() {
        this.state = observable({
            openNotify: false
        })
    }
    get userName() {
        return userModel.user.name;
    }
    logout() {
        LoginService.logout();
    }
    openUserSetting() {
        runInAction(() => {
            portalModel.content = <UserSetting />;
            portalModel.title = '나의 계정'
        })
    }
}
export default new HeaderVm();