import RefundDetailModel from '@model/RefundDetailModel';
import { observable, runInAction, toJS } from 'mobx';
import RefundDetailService from '@service/RefundDetailService';
class UnableAlimtalkSendModalVm {
    constructor() {
        this.state = observable({
            sendList: []
        })
    }
    get personInCharges() {
        return RefundDetailModel.personInCharges;
    }
    sendList(e, id, key) {
        const checked = e.target.checked;
        let hasItem = false;
        this.state.sendList.forEach(sendItem => {
            if(sendItem.id === id) {
                hasItem = true;
                sendItem[key] = checked;
            }
        });
        if(hasItem === false) {
            this.state.sendList.push({
                id : id,
                [key] : checked
            })
        }
    }
    sendChecked(id, key) {
        let checked = false;
        if(checked === false) {
            this.state.sendList.forEach(sendItem => {
                if(sendItem.id === id) {
                    if(sendItem[key]) {
                        checked = true;
                    } 
                }
            });
        }
        return checked;
    }
    get sendActive() {
        let active = true;
        let sendList = false;
        this.state.sendList.forEach(send => {
            if(send?.sendAlimtalk === true) {
                sendList = true;
            }
        });
        active = sendList;
        return active;
    }
    send(customerId) {
        let personInChargeCustomerIds = [];
        this.state.sendList.forEach((send) => {
            if(send?.sendAlimtalk === true) {
                personInChargeCustomerIds.push(send.id);
            }
        })
        const param = {
            personInChargeCustomerIds
        }
        RefundDetailService.unableAlimtalkSend(param, customerId)
    }
}
export default new UnableAlimtalkSendModalVm();