import './App.scss';
import { Route, BrowserRouter, Routes, Navigate } from "react-router-dom";
import AppVm from './AppVm';
import { useObserver } from 'mobx-react';
import { useEffect } from 'react';
import Popup from '@standby/common-ui/components/molecules/Popup/Popup';
import Dialog from '@standby/common-ui/components/molecules/Dialog/Dialog';
import Auth from '@pages/Auth/Auth';
import Logined from '@pages/Logined/Logined';
import CustomerList from '@pages/Customer/CustomerList';
import RefundList from '@pages/Refund/RefundList';
import Timeline from '@pages/Timeline/Timeline';
import CustomerDetail from '@pages/CustomerDetail/CustomerDetail';
import RefundDetail from '@pages/Refund/Detail/Detail';

function App() {
  useEffect(() => {
    if(AppVm.isLogined) {
      AppVm.loginedStart();
    }
  }, [])
  return useObserver(() => (
    <div id="app">
      {AppVm.popupContent && 
        <Popup title={AppVm.popupTitle || ''} content={AppVm.popupContent} onClose={() => AppVm.popupClose()} portalModel={AppVm.portalModel}/>
      }
      {AppVm.dialogType &&
        <Dialog type={AppVm.dialogType} title={AppVm.dialogTitle} content={AppVm.dialogContent} btn={AppVm.dialogBtn} alertBtnText={AppVm.dialogAlertBtnText} confirmBtnText={AppVm.dialogConfirmBtnText} cancelBtnText={AppVm.dialogCancelBtnText} contentJSX={AppVm.dialogContentJSX}/>
      }
      <Routes>
        {/* 로그인없이 접근가능한 url */}
        {/*회원 인증관련 페이지 (로그인,회원가입,비번찾기등)*/}
        <Route path="/auth/*" element={AppVm.isLogined ? <></> : <Auth/>}/>

        {/* 로그인후 접근가능한 url */}
        <Route path="/" element={<LoginedRenderCompoent component={<RefundList/>}/>} exact/>
        <Route path="/customer/list" element={<LoginedRenderCompoent component={<CustomerList/>}/>} exact/>
        <Route path="/refund/list" element={<LoginedRenderCompoent component={<RefundList/>}/>} exact/>
        <Route path="/timeline" element={<LoginedRenderCompoentNotFrame component={<Timeline/>}/>}/>
        <Route path="/customer/detail" element={<LoginedRenderCompoentNotFrame component={<CustomerDetail/>}/>}/>
        <Route path="/refund/detail" element={<LoginedRenderCompoentNotFrame component={<RefundDetail/>}/>}/>
      </Routes>
    </div>
  ));
}

function LoginedRenderCompoent(props) {
  return useObserver(() => (
    <>
      { (AppVm.user.name || AppVm.isLogined) ?
          <Logined>{props.component}</Logined>
        : 
          <Navigate to="/auth/login" replace={true}/>
      }
    </>
  ));
}

function LoginedRenderCompoentNotFrame(props) {
  return useObserver(() => (
    <>
      { (AppVm.user.name || AppVm.isLogined) ?
          <>{props.component}</>
        : 
          <Navigate to="/auth/login" replace={true}/>
      }
    </>
  ));
}

export default App;
