import { useObserver } from 'mobx-react';
import { Route, withRouter } from "react-router-dom";
import { useEffect } from 'react';
import vm from './DelayAlimtalkSendModalVm';
import st from './DelayAlimtalkSendModal.module.scss';
import CheckBox from '@standby/common-ui/components/atoms/Input/CheckBox/CheckBox';
import MessageHint from '@commonComponents/MessageHint/MessageHint';

function DelayAlimtalkSendModal(props) {
    return useObserver(() => (
        <div className={st.modalContent}>
            <div className={st.sendList}>
                <div className={st.title}>발송방식</div>
                <div>
                    {vm.personInCharges.map((personInCharge, index) => (
                        <ul className={st.checkboxList} key={index}>
                            {personInCharge.phoneNumber && 
                                <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendAlimtalk')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendAlimtalk')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}알림톡 {personInCharge.phoneNumber}</CheckBox></li>
                            }
                            {personInCharge.email && 
                                <li><CheckBox checked={vm.sendChecked(personInCharge.id, 'sendEmail')} onChange={(e) => vm.sendList(e, personInCharge.id, 'sendEmail')}>{personInCharge?.name ? `(${personInCharge?.name}) ` : ''}메일 {personInCharge.email}</CheckBox></li>
                            }
                        </ul>
                    ))}
                </div>
            </div>
            <div className={st.btnWrap}>
                <button type='button' disabled={!vm.sendActive} className={st.mainBtn} onClick={() => vm.send(props.customerId)}><span>안내 보내기</span></button>
            </div>
            <MessageHint text="알림톡, 이메일 미리보기" style={{marginTop:'4px'}}>
                <img src='/images/delayAlimtalkPreview.svg'/>
            </MessageHint>
        </div>
    ));
}
export default DelayAlimtalkSendModal;