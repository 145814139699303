import { observable } from 'mobx';

const RefundDetailModel = observable({
    taxRefundApplication: {
        provisionalCalcAmount: "",
        businessName : "",
        provisionalCalcCommissionRate: "",
        taxRefundStatusType : "",
        taxRefundLastStepType: "",
        canProvideService: null,
        acceptAt: "",
        actualAmount: "",
        refundAmount: "",
        refundCommissionRate: "",
        discountAmount: "",
        discountRate: "",
        discountType: "",
        retainedAmount: "",
        depositAt: "",
        refundBank: "",
        refundAccount: "",
        refundAccountHolder: ""
    },
    representatives: [],
    personInCharges: []
});

export default RefundDetailModel;