import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import clsx from 'clsx';
import queryString from 'query-string';
import st from './CustomerDetail.module.scss';
import Radio from '@standby/common-ui/components/atoms/Input/Radio/Radio';
import InputText from '@standby/common-ui/components/atoms/Input/InputText/InputText';
import vm from './CustomerDetailVm';
import PlusBtn from '@standby/common-ui/components/atoms/Button/PlusBtn/PlusBtn';
import MinusBtn from '@standby/common-ui/components/atoms/Button/MinusBtn/MinusBtn';
import Select from '@standby/common-ui/components/atoms/Input/Select/Select';
import SquareBtn from '@standby/common-ui/components/atoms/Button/SquareBtn/SquareBtn';

function CustomerDetail() {
    let location = useLocation();
    const customerId = queryString.parse(location.search).customerId;
    useEffect(() => {
        vm.load(customerId);
    }, [])
    return useObserver(() => (
        <div className={st.wrap}>
            <div className={st.pageTitle}>
                <span className={st.title}>고객정보</span>
                {customerId && 
                    <div className={st.btns}>
                        <button className={st.lineBtn} type='button' onClick={() => vm.deleteCustomer(customerId)}>고객삭제</button>
                        <button className={st.arrowBtn} type='button' onClick={() => vm.openAlimtalkModal(customerId)}>수동 알림톡 발송</button>
                    </div>
                }
            </div>
            <div className={st.pageContent}>
                <div className={st.inputRows}>
                    <div className={clsx(st.rowTitle, st.required)} style={{top:"4px"}}>고객 종류</div>
                    <div className={st.rowContent}>
                        <ul className={st.radioList}>
                            <li><Radio name="customerType" value="법인" checked={vm.customerType === "법인"} onChange={vm.changeCustomerType}>법인</Radio></li>
                            <li><Radio name="customerType" value="개인" checked={vm.customerType === "개인"} onChange={vm.changeCustomerType}>개인</Radio></li>
                        </ul>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={clsx(st.rowTitle, st.required)}>사업체명</div>
                    <div className={st.rowContent}>
                        <InputText name="" value={vm.businessName} onChange={vm.changeBusinessName} style={{width:"356px"}}/>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={clsx(st.rowTitle, st.required)}>사업자등록번호</div>
                    <div className={st.rowContent}>
                        <InputText name="" value={vm.businessRegistrationNumber} onChange={vm.changeBusinessRegistrationNumber} style={{width:"356px"}}/>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={clsx(st.rowTitle, st.required)}>대표자 성함</div>
                    <div className={st.rowContent}>
                        {vm.representative.map((representative, index) => (
                            <div className={st.seconRow} key={index} style={{width:"356px"}}>
                                <InputText name="" value={representative} onChange={(e) => vm.changeRepresentative(e, index)} style={{width:"335px"}}/>
                                <PlusBtn className={st.plusInput} onClick={() => vm.addRepresentative(index)}/>
                                <MinusBtn className={st.minusInput} onClick={() => vm.removeRepresentative(index)} disabled={vm.representative.length <= 1}/>
                            </div>
                        ))}
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={clsx(st.rowTitle, st.required)}>사업장 주소</div>
                    <div className={st.rowContent}>
                        <InputText name="" value={vm.address} onChange={vm.changeAddress} style={{width:"356px"}}/>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={st.rowTitle}>세금환급 담당자</div>
                    <div className={st.rowContent}>
                        <Select name="" style={{width:"298px"}} value={vm.selectedTaxRefundOfficerId} onChange={vm.changeSelectedTaxRefundOfficerId}>
                            <option value="">-</option>
                            {vm.taxRefundOfficers.map((taxRefundOfficer, index) => (
                                <option key={index} value={taxRefundOfficer.id}>{taxRefundOfficer.name}</option>
                            ))}
                        </Select>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={st.rowTitle}>고객측 담당자</div>
                    <div className={st.rowContent}>
                        <ul className={st.personInChargeCustomer}>
                            {vm.personInChargeCustomers.map((personInChargeCustomer, index) => (
                                <li key={index}>
                                    <div className={st.inputRows}>
                                        <div className={st.rowTitle}>성함</div>
                                        <div className={st.rowContent}>
                                            <InputText name="" value={personInChargeCustomer.name} onChange={(e) => vm.changePersonInChargeCustomerName(e, index)} style={{width:"263px"}}/>
                                        </div>
                                    </div>
                                    <div className={st.inputRows}>
                                        <div className={clsx(st.rowTitle, st.required)}>휴대폰 번호</div>
                                        <div className={st.rowContent}>
                                            <InputText name="" value={personInChargeCustomer.phoneNumber} onChange={(e) => vm.changePersonInChargeCustomerPhoneNumber(e, index)} style={{width:"263px"}}/>
                                        </div>
                                    </div>
                                    <div className={st.inputRows}>
                                        <div className={clsx(st.rowTitle, st.required)}>이메일</div>
                                        <div className={st.rowContent}>
                                            <InputText name="" value={personInChargeCustomer.email} onChange={(e) => vm.changePersonInChargeCustomerEmail(e, index)} style={{width:"263px"}}/>
                                        </div>
                                    </div>
                                    <div className={st.btns}>
                                        {vm.personInChargeCustomers.length > 1 && 
                                            <SquareBtn color='white' onClick={() => vm.removePersonInChargeCustomer(index)}>삭제하기</SquareBtn>
                                        }
                                        <SquareBtn color='white' onClick={() => vm.addPersonInChargeCustomer(index)}>추가하기</SquareBtn>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
                <div className={st.inputRows}>
                    <div className={st.rowTitle}>추천인</div>
                    <div className={st.rowContent}>
                        <InputText name="" value={vm.referrer} onChange={vm.changeReferrer} style={{width:"356px"}}/>
                    </div>
                </div>
                {customerId ?
                    <button type="button" className={st.fixedSave} disabled={!vm.saveBtnActive} onClick={() => vm.updateCustomer(customerId)}>수정사항 저장</button>
                    :
                    <div className={st.save}>
                        <SquareBtn color='white' onClick={() => vm.saveCustomer()} disabled={!vm.saveBtnActive}>고객등록하기</SquareBtn>
                    </div>
                }
            </div>
        </div>
    ));
}
export default CustomerDetail;