import { post, get, put } from '@common/module/httpRequest';
import { runInAction } from 'mobx'; 
import CustomerListModel from '@model/CustomerListModel';

class CustomerListService {
    async load() {
        const res = await get(`/admin/customer/grid/column`);
        CustomerListModel.setColumns(res.data.columns);
    }
    async gridData(params) {
        const res = await post('/admin/customer/grid', params);
        return res.data;
    }
    async onChangeData(params) {
        await put('/admin/customer/grid/column', params);
    }
}
export default new CustomerListService();