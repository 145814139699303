import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {pageRouter} from '@model/pageModel';

import { Router, unstable_HistoryRouter as HistoryRouter } from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <HistoryRouter history={pageRouter.history}>
      <App />
    </HistoryRouter>
);

