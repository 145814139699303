import RefundListService from '@service/RefundListService';
import RefundListModel from '@model/RefundListModel';
import { get, post } from '@common/module/httpRequest';
import { runInAction, observable, toJS } from "mobx";
import { pageRouter } from '@model/pageModel';
import { _alert } from "@model/dialogModel";

class RefundListVm {
    constructor() {
        this.state = observable({
            activeTab: null
        })
    }
    load() {
        RefundListModel.columns = [];
        RefundListService.load();
    }
    get columns() {
        return RefundListModel.columns;
    }
    get tabs() {
        return RefundListModel.tabs;
    }
    onTab(taxRefundGridTabType) {
        if(taxRefundGridTabType) {
            pageRouter.replace(`/refund/list?type=${taxRefundGridTabType}`);
        }else{
            pageRouter.replace(`/refund/list`);
        }
    }
    gridData(server) {
        return {
            getRows : async params => {
                let jsonRequest = params.request;
                jsonRequest.tab = this.state.activeTab;
                const res = await RefundListService.gridData(jsonRequest);
                params.successCallback(res.rows.data, res.rows.lastRow);
            }
        }
    }
    onGridPageSizeChanged(e, gridRef) {
        const value = e.target.value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }
    onChangeData(e) {
        const sendData = {
            type: e.type,
            pinned: e.pinned,
            visible: e.visible,
            colIds: e?.columns?.map(column => column.colId),
            value: e.value,
            colId: e?.column?.colId,
            rowData: e.data
        }
        RefundListService.onChangeData(sendData);
    }
}
export default new RefundListVm();