import TimelineService from "@service/TimelineService";
import TimelineModel from "@model/TimelineModel";
import { runInAction, observable, toJS } from "mobx";
import { _alert, _confirm } from "@model/dialogModel";
import formDataToJson from '@common/module/submit';
import portalModel from '@model/portalModel';

class TimelineVm {

    constructor() {
        this.state = observable({
            activeTab : null,
            message : "",
            pinned : true
        })
    }
    getTimeline(customerId, type) {
        TimelineService.load(customerId, type);
    }
    get normalTimeLines() {
        return TimelineModel.normalTimeLines;
    }
    get pinnedTimeLines() {
        return TimelineModel.pinnedTimeLines;
    }
    changeTab(customerId, type) {
        if(type !== this.state.activeTab) {
            this.state.activeTab = type;
            this.getTimeline(customerId, this.state.activeTab)
        }
    }
    message(e, textarea) {
        textarea.current.style.height = 'auto';
        textarea.current.style.height = textarea.current.scrollHeight + 2 + 'px';
        this.state.message = e.target.value;
    }
    async submit(e, customerId, textarea) {
        e.preventDefault();
        let param = formDataToJson(e.target).data;
        if(!param.message) {
            return await _alert('메모를 입력해주세요.');
        }
        await TimelineService.saveMessage(param, customerId, this.state.activeTab);
        this.state.message = "";
        textarea.current.style.height = 'auto';
    }
    async pin(customerId, timelineId, pinned) {
        TimelineService.pin(customerId, timelineId, pinned, this.state.activeTab);
    }
    async delete(customerId, timelineId) {
        if(await _confirm('삭제하시겠습니까?')) {
            TimelineService.delete(customerId, timelineId, this.state.activeTab);
        }
    }
}
export default new TimelineVm();