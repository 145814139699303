import CustomerListService from "@service/CustomerListService";
import CustomerListModel from "@model/CustomerListModel";

class CustomerListVm {
    load() {
        CustomerListModel.columns = [];
        CustomerListService.load();
    }
    get columns() {
        return CustomerListModel.columns;
    }
    gridData(server) {
        return {
            getRows : async params => {
                let jsonRequest = params.request;
                const res = await CustomerListService.gridData(jsonRequest);
                params.successCallback(res.data, res.lastRow);
            }
        }
    }
    onGridPageSizeChanged(e, gridRef) {
        const value = e.target.value;
        gridRef.current.api.paginationSetPageSize(Number(value));
    }
    openWindow() {
        window.open(window.location.origin + `/customer/detail`, `customerDetail`, `width=1140, height=${window.screen.height}, toolbar=0,location=0,menubar=0`);
    }
    onChangeData(e) {
        const sendData = {
            type: e.type,
            pinned: e.pinned,
            visible: e.visible,
            colIds: e?.columns?.map(column => column.colId),
            value: e.value,
            colId: e?.column?.colId,
            rowData: e.data
        }
        CustomerListService.onChangeData(sendData);
    }
}
export default new CustomerListVm();