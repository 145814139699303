import { useObserver } from 'mobx-react';
import { Route, withRouter, useLocation } from "react-router-dom";
import { useEffect } from 'react';
import vm from './ProcessBtnRendererVm';
import st from './ProcessBtnRenderer.module.scss';
import queryString from 'query-string';

function ProcessBtnRenderer(props) {
    let location = useLocation();
    const selectedIndex = queryString.parse(location.search).selectedIndex;

    return useObserver(() => (
        <button type='button' className={st.gridBtn} onClick={() => vm.openWindow(props.data.customerId, selectedIndex)}>
            세금환급 처리
        </button>
    ));
}
export default ProcessBtnRenderer;